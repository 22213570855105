@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Patua+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Styles in order of appearance */

h1 {
  font-family: 'Patua One', cursive;
  font-weight: 50;
  font-size: calc(4em + 6vmin);
  color: white;
  margin: 0;
  padding: 0;
  text-align: center;
}

p {
  font-family: 'Patua One', cursive;
  text-align: center;
}

.p2 {
  text-align: center;

}

p3 {
  font-size: small;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  max-height: 6vh;
  padding-bottom: 1vh;
}

i {
  color:#FFFFFF;
  padding-left: 1em;
}

#section02 a {
  background-color: #5a97fa;
}

.demo a {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  font : normal 400 20px/1 'Josefin Sans', sans-serif;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;
}

.demo a:hover {
  opacity: .5;
}

.App-header {
  background-color: #5a97fa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
  position: relative;
  width: 100%;
  height: 100vh;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  min-height: 97vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#part_with_intro {
  background-color: #5a97fa;
  font-family: 'Permanent Marker', cursive;
  color: white;
  text-align: center;
  -webkit-animation: titles 2s ease-in-out;
          animation: titles 2s ease-in-out;
}

#section02 a {
  padding-top: 70px;
  font-family: Racing Sans, cursive;
  text-align: center;
  background-color: #5a97fa;
}

#section02 a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 0.5s infinite;
  animation: sdb05 0.5s infinite;
  box-sizing: border-box;
  
}

.star {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  -webkit-animation: stars 8s ease-in-out infinite;
          animation: stars 8s ease-in-out infinite;
}

.star.star1 {
  background: url(/static/media/star1.625e6cad.png);
}

.star.star2 {
  background: url(/static/media/star2.da5717ac.png);
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.star.star3 {
  background: url(/static/media/star3.3b700035.png);
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}
.topnav {
  background-color: #5a97fa;
  overflow: hidden;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #4CAF50;
  color: white;
}

@-webkit-keyframes stars {
  0%, 25%, 50%, 75%, 100%
  {
    opacity: 0;
  }
  
  12.5%, 37.5%, 62.5%, 87.5%
  {
    opacity: 1;
  }
 
}

@keyframes stars {
  0%, 25%, 50%, 75%, 100%
  {
    opacity: 0;
  }
  
  12.5%, 37.5%, 62.5%, 87.5%
  {
    opacity: 1;
  }
 
}

@-webkit-keyframes titles {
  0%
  {
    opacity: 0;
  }
  100%
  {
    opacity: 1;
  }
}

@keyframes titles {
  0%
  {
    opacity: 0;
  }
  100%
  {
    opacity: 1;
  }
}

@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
            transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
            transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

@keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
            transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
            transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

